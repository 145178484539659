import * as React from "react";
import { graphql } from "gatsby";
import IndexPageComponent from "src/components/pages";

const IndexPage = ({ data, location }) => {
  const copy = {
    bannerText: "Spetner Life Insurance",
    video: {
      title: "Welcome",
      text: `In Israel today there is no such thing as permanent insurance:
            insurance that is guaranteed to last one’s entire lifetime. In the
            American life insurance market, there are products which are
            guaranteed to stay in force until age one hundred and twenty,
            literally.`,
    },
    quoteButton: "Get a Quote",
    readMore: "Read More",
    homeMainItems: [
      {
        title: "Why Buy Life Insurance",
        text: `Life insurance protects against financial loss that may be
              incurred at the death of the insured. Here are some needs for the
              protection that life insurance provides:`,
        href: `/learn/who-needs-life-insurance`,
      },
      {
        title: "U.S.A. vs Israel",
        text: `Life insurance sold in Israel today is far inferior to that which
              is sold in the USA in terms of both price and product quality.
              Whereas a US resident can enjoy risk/term life insurance at very
              affordable prices with contracts that guarantee no increase in
              premium for 10/15/20/25 and even 30 years, similarly healthy
              residents of Israel pay initial premium as much as 3 to 4 times as
              high and the cost goes up each policy year.`,
        href: `/learn/why-buy-us-life-insurance`,
      },
    ],
    testimonials: {
      title: "Testimonials",
      items: [
        {
          author: "Dr. Michael Goldenhersh",
          text: `We started out with the father and continued with the son,
              Jonathan, who continued the tradition of the father which is that
              the people are very nice, loyal and trustworthy.`,
        },
        {
          author: "Zvi Sand",
          text: `I was very impressed with his knowledge, his capabilities; every
              question that I raised to him…he immediately answered.`,
        },
        {
          author: "Charlie Deutsch",
          text: `\u2026anybody who’s been around the St. Louis community knows the name Spetner.
            It’s a tradition in caring and service for St. Louis.`,
        },
      ],
    },
    blogPosts: {
      title: "Latest Blog Posts",
      items: data.allFile.nodes.map((node) => ({
        title: node.childMdx.frontmatter.title,
        preview: node.childMdx.excerpt,
        href: `/blog/${node.childMdx.slug}`,
      })),
    },
    insuranceQuotes: {
      title: "Life Insurance Quotes",
      text: `Click here for a free life insurance quote and see how affordable
          American life insurance can be.`,
    },

    contact: {
      title: "Contact Us",
      fields: {
        name: "Name",
        phone: "Phone",
        email: "Email",
        message: "Message",
      },
      success: (
        <span>
          Thank you for your message.
          <br />
          Someone will be in touch with you shortly.
        </span>
      ),
      error: "An error has occurred. Please try again later.",
      button: "Submit",
    },
  };

  return (
    <IndexPageComponent
      lang="en"
      location={location}
      pageTitle="Home"
      copy={copy}
      pageName="home"
      bannerText={copy.bannerText}
    ></IndexPageComponent>
  );
};

export const query = graphql`
  query {
    allFile(
      filter: {
        childMdx: { id: { ne: null } }
        sourceInstanceName: { eq: "blog" }
      }
      limit: 3
      sort: { fields: childMdx___frontmatter___date, order: DESC }
    ) {
      nodes {
        childMdx {
          excerpt
          slug
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
          }
        }
      }
      totalCount
    }
  }
`;
export default IndexPage;
